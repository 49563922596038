import { GetServerSideProps } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'

import { NotFound } from '@/components/error'

export default function Home() {
  return <NotFound />
}

export const getServerSideProps: GetServerSideProps = async ({ locale }) => {
  const localeValue = locale as string
  return {
    props: {
      ...(await serverSideTranslations(localeValue, 'web-view-item')),
    },
  }
}
